import React, { Component } from "react";

class ManufacturingLocationSearchResult extends Component {

    render() {

        return (
            
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th scope="col" style={{width: "10%"}}>Vendor Id</th>
                        <th scope="col" style={{width: "10%"}}>Location Id</th>
                        <th scope="col" style={{width: "10%"}}>Location Code</th>
                        <th scope="col" style={{width: "36%"}}>Location Name</th>
                        <th scope="col" style={{width: "14%"}}>Integration Status</th>
                        <th scope="col" style={{width: "20%"}}>Date</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {this.props.list.map(item => (
                        <tr>
                            <td style={{width: "10%"}}>{item.VendorIdentifier}</td>
                            <td style={{width: "10%"}}>{item.LocationIdentifier}</td>
                            <td style={{width: "10%"}}>{item.Code}</td>
                            <td style={{width: "36%"}}>{item.Name}</td>
                            <td style={{width: "14%"}}>
                                {item.IntegrationStatus === "Success" ?
                                    <span class="badge badge-success">{item.IntegrationStatus}</span>
                                :
                                item.IntegrationStatus === "Block" ?
                                    <span class="badge badge-warning">{item.IntegrationStatus}</span>
                                :
                                item.IntegrationStatus === "Manual Trigger" ?
                                    <span class="badge badge-dark">{item.IntegrationStatus}</span>
                                :
                                    <span class="badge badge-danger">{item.IntegrationStatus}</span>}
                            </td>
                            <td style={{width: "20%"}}>{item.Date}</td>
                        </tr>
                    ))}
                   
                </tbody>
            </table>
            
        );
    };
};

export default ManufacturingLocationSearchResult;