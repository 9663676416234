import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import { MdWarning } from "react-icons/md";
import { MdSubscriptions } from "react-icons/md";
import { MdBlock } from "react-icons/md";
import { MdRemoveRedEye } from "react-icons/md";
import { TiFlowMerge } from "react-icons/ti";
import { MdBrokenImage } from "react-icons/md";
import { MdHistory } from "react-icons/md";


class Card extends Component {

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                  {
                    {
                      "Manufacturing Location": <MdLocationOn />,
                      "Block": <MdBlock />,
                      "Manual Trigger": <TiFlowMerge />,
                      "Alert Log": <MdWarning />,
                      "Alert Log Subscription": <MdSubscriptions />,
                      "Watch": <MdRemoveRedEye />,
                      "Failure": <MdBrokenImage />,
                      "Process History": <MdHistory />
                    }[this.props.title]
                  }
                &nbsp;{this.props.title}
                </h6>
                <div class="border-top my-2"></div>
                <p class="card-text">{this.props.body}</p>
                <button class="btn  btn-outline-primary btn-sm">
                  <Link to={this.props.link} style={{ textDecoration: "none"}}>{this.props.action}</Link>
                </button>
              </div>
            </div>
            
        );
    };
};

export default Card;