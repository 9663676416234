const Config = {
    ClientId: process.env.REACT_APP_CLIENT_ID,
    OktaAuthorizeUrl: process.env.REACT_APP_OKTA_AUTHORIZE_URL,
    OktaTokenUrl: process.env.REACT_APP_OKTA_TOKEN_URL,
    AravoUrl: process.env.REACT_APP_ARAVO_URL,
    SmsoUrl: process.env.REACT_APP_SMSO_URL,
    IntegrationStatusUrl: process.env.REACT_APP_INTEGRATION_STATUS_URL,
    ServiceStatusUrl: process.env.REACT_APP_SERVICE_STATUS_URL,
    ManufacturingLocationUrl: process.env.REACT_APP_MANUFACTURING_LOCATION_URL,
    AlertLogUrl: process.env.REACT_APP_ALERT_LOG_URL,
    BlockUrl: process.env.REACT_APP_BLOCK_URL,
    ManualTriggerUrl: process.env.REACT_APP_MANUAL_TRIGGER_URL,
    AlertLogSubscriptionUrl: process.env.REACT_APP_ALERT_LOG_SUBSCRIPTION_URL,
    WatchUrl: process.env.REACT_APP_WATCH_URL,
    FailureUrl: process.env.REACT_APP_FAILURE_URL,
    ProcessHistoryUrl: process.env.REACT_APP_PROCESS_HISTORY_URL,
    SourceReasonListUrl: process.env.REACT_APP_SOURCE_REASON_LIST_URL,
    SourcingGroupListUrl: process.env.REACT_APP_SOURCING_GROUP_LIST_URL,
    UnblockUrl: process.env.REACT_APP_UNBLOCK_URL
};

module.exports = Config;