import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Config from "../Config";
import Alert from "../components/Alert";
import ManufacturingLocationSearchResult from "../components/ManufacturingLocationSearchResult";
import { MdInfoOutline } from "react-icons/md";

class ManufacturingLocation extends Component {

  state = {
    ManufacturingLocationData : null,
    ManufacturingLocationResult : null,
    SearchFor : null
  };

  getManufacturingLocation(){
    fetch(Config.ManufacturingLocationUrl + this.state.SearchFor).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      this.setState({ ManufacturingLocationData: data });
      this.setState({ ManufacturingLocationResult: "Success" });
    })
    .catch((error) => {
      this.setState( {ManufacturingLocationResult: "Failure"} )
  })};

  onSearch = () => {
    this.setState({ ManufacturingLocationResult: null });
    this.setState( {ManufacturingLocationResult: "Loading"} );
    this.getManufacturingLocation();
  };

  onChange = (evt) => {
    this.setState({ SearchFor: evt.target.value });
  }

  render() {

    return (

    
      <div>

        <BreadCrumb currentpage="Manufacturing Location" />

        <div class="row">

          <div  class="col-sm-10">
             <h6 class="display-5">Search for a Vendor or Manufacturing Location in the Data Integration Pipeline - </h6>
               
              <div class="form-group input-group-sm">
                  <input type="text" class="form-control" id="query" onChange={this.onChange} />
                  <small id="help" class="form-text text-info"><MdInfoOutline />&nbsp;Enter Global Vendor Identifier or Global Manufacturing Location Identifier or Code or Name (Wildcard). Empty search input will return all manufacturing locations.</small>
              </div>
              <button type="submit" class="btn btn-outline-primary btn-sm" onClick={this.onSearch}>Search</button>
          
          </div>
        
        </div>

        <br />

        <div>

            {
              this.state.ManufacturingLocationResult == null ?

                null

              : this.state.ManufacturingLocationResult === "Loading" ?

                <Alert message="Please wait...searching"
                styleClass="alert alert-primary"/>
  
              : this.state.ManufacturingLocationResult === "Failure" ?

                <Alert message="Server error. Please try again later!"
                styleClass="alert alert-danger"/>

              : this.state.ManufacturingLocationData.length === 0 ?

                <Alert message="No matching records found."
                styleClass="alert alert-success"/>
              
              :
                <ManufacturingLocationSearchResult list={this.state.ManufacturingLocationData} />
            }

          </div>

        </div>
      
    );

  };

};

export default ManufacturingLocation;
