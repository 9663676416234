import React, { Component } from "react";
import { MdInfoOutline } from "react-icons/md";
import ModalUnblockForm from "./ModalUnblockForm";

class BlockList extends Component {

  state = {
    selectedLocationName: "",
    selectedLocationId: ""
  };

  onLocationCodeClick = (evt) => {
    this.setState({selectedLocationName: evt.target.name});
    this.setState({selectedLocationId: evt.target.id});
  };

    render() {

      let list;
      if (this.props.status === "Loading") {
        list =  <div class="alert alert-primary" role="alert">
                 Loading...
                </div>
      }
     
      if(this.props.status === "Success" && this.props.list.length > 0) {
        list = 
        <div>
          <h6 class="display-5">Blocked Manufacturing Location(s) - </h6>
          <div class="row">
            <div class="col-sm bg-light">
                <small class="text-info align-items-center">
                  <MdInfoOutline />&nbsp;The data integration pipeline will automatically unblock the blocked location when the location is integrated next time.
                </small>
            </div>
         </div>
          <table class="table table-sm">
            <thead>
                <tr>
                    <th scope="col" style={{width: "10%"}}>Location Id</th>
                    <th scope="col" style={{width: "10%"}}>Location Code</th>
                    <th scope="col" style={{width: "30%"}}>Location Name</th>
                    <th scope="col" style={{width: "20%"}}>Block Date</th>
                    <th scope="col" style={{width: "30%"}}>Reason</th>
                </tr>
            </thead>
            <tbody>
                {this.props.list.map(item => (
                  <tr>
                    {item.Reason.includes("material supplier location") ?
                    <td>
                        <a href={"#" + item.Code} name={item.Name} id={item.GlobalVendorIdentifier} onClick={this.onLocationCodeClick} style={{ textDecoration: "none"}} data-toggle="modal" data-target="#Unblock">
                          {item.GlobalVendorIdentifier}
                        </a>
                    </td>
                    :
                    <td>{item.GlobalVendorIdentifier}</td>}
                    <td>{item.Code}</td>
                    <td>{item.Name}</td>
                    <td>{item.BlockDate}</td>
                    <td>{item.Reason}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      }
      else if(this.props.status === "Success" && this.props.list.length === 0) 
      {
        list = <div class="alert alert-success" role="alert">
                  No manufacturing locations are currently blocked.
              </div>
      }

      if(this.props.status === "Failure") {
        list = <div class="alert alert-danger" role="alert">
                  Server error occurred while getting the currently blocked manufacturing locations. 
                  Please try again later!
               </div>
      }

        return (

            <div>
               {list}
               <ModalUnblockForm 
                  selectedLocationName = {this.state.selectedLocationName}
                  selectedLocationId = {this.state.selectedLocationId} />
            </div>
            
        );
    };
};

export default BlockList;