import React, { Component } from "react";
import ManualTriggerWindow from "../components/ManualTriggerWindow";
import { MdInfoOutline } from "react-icons/md";

class ManualTriggerSearchResult extends Component {

    state = {
        SelectedVendorId : null,
        SelectedLocationId : null,
        SelectedLocationName : null
    };

    onManualTrigger = (VendorId, LocationId, LocationName) => {
        this.setState({ SelectedVendorId: VendorId });
        this.setState({ SelectedLocationId: LocationId });
        this.setState({ SelectedLocationName: LocationName });
    };

    render() {

        return (

        <div>

            <div class="row">
                <div class="col-sm bg-light">
                    <small class="text-info align-items-center"><MdInfoOutline />&nbsp;You can set up a manufacturing location for integration here in the pipeline but have to wait for Data 360 to trigger the integration. The time window for the trigger is not guaranteed. If urgent, trigger in Aravo.</small>
                </div>
            </div>
            
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">Vendor Id</th>
                        <th scope="col">Location Id</th>
                        <th scope="col">Location Code</th>
                        <th scope="col">Location Name</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {this.props.list.map(item => (
                        <tr>
                            <td>{item.VendorGvid}</td>
                            <td>{item.LocationGvid}</td>
                            <td>{item.Code}</td>
                            <td>{item.Name}</td>
                            <td>
                                {
                                    item.Status === "" ? 
                                        <button class="btn  btn-outline-primary btn-sm"
                                        data-toggle="modal" data-target="#ManualTriggerWindow" 
                                        onClick={() => this.onManualTrigger(item.VendorGvid, item.LocationIdentifier, item.Name)}>
                                            Manual Trigger
                                        </button>
                                    : item.Status
        
                                }
                            </td>
                        </tr>
                    ))}
                   
                </tbody>
            </table>

            <div>
              <ManualTriggerWindow title="Manual Trigger"
              vendorid={this.state.SelectedVendorId}
              locationid={this.state.SelectedLocationId}
              locationname={this.state.SelectedLocationName}/>
          </div>

        </div>
            
        );
    };
};

export default ManualTriggerSearchResult;