import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Config from "../Config";
import Alert from "../components/Alert";
import ManualTriggerSearchResult from "../components/ManualTriggerSearchResult";
import { MdInfoOutline } from "react-icons/md";

class ManualTrigger extends Component {

  state = {
    ManualTriggerData : null,
    ManualTriggerResult : null,
    SearchFor : null
  };

  getManualTrigger(){
    fetch(Config.ManualTriggerUrl + this.state.SearchFor).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      this.setState({ ManualTriggerData: data });
      this.setState({ ManualTriggerResult: "Success" });
    })
    .catch((error) => {
      this.setState( {ManualTriggerResult: "Failure"} )
  })};

  onSearch = () => {
    this.setState({ ManualTriggerResult: null });
    if(this.state.SearchFor != null && 
        this.state.SearchFor !== "") {
      this.setState( {ManualTriggerResult: "Loading"} );
      this.getManualTrigger();
    };
  };

  onChange = (evt) => {
    this.setState({ SearchFor: evt.target.value });
  }

  render() {

    return (

    
      <div>

        <BreadCrumb currentpage="Manual Trigger" />

        <div class="row">

          <div  class="col-sm-6">
             <h6 class="display-5">Search for a vendor or manufacturing location in the data integration pipeline - </h6>
               
              <div class="form-group input-group-sm">
                  <input type="text" class="form-control" id="query" onChange={this.onChange} />
                  <small id="help" class="form-text text-info"><MdInfoOutline />&nbsp;Enter Global Vendor Identifier or Global Manufacturing Location Identifier or Code.</small>
              </div>
              <button type="submit" class="btn btn-outline-primary btn-sm" onClick={this.onSearch}>Search</button>
          </div>
        
        </div>

        <br />

        <div>

            {
              this.state.ManualTriggerResult === null ?

                null

              : this.state.ManualTriggerResult === "Loading" ?

                <Alert message="Please wait...searching"
                styleClass="alert alert-primary"/>
  
              : this.state.ManualTriggerResult === "Failure" ?

                <Alert message="Server error. Please try again later!"
                styleClass="alert alert-danger"/>

              : this.state.ManualTriggerData.length === 0 ?

                <Alert message="No matching records found."
                styleClass="alert alert-success"/>
              
              :
                <ManualTriggerSearchResult list={this.state.ManualTriggerData} />
            }

          </div>

        </div>
      
    );

  };

};

export default ManualTrigger;
