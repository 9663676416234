import React, { Component } from "react";
import { MdInfoOutline } from "react-icons/md";

class AlertLogSearchResult extends Component {

    render() {

        return (

        <div>

            <div class="row">
                <div class="col-sm bg-light">
                    <small class="text-info align-items-center"><MdInfoOutline />&nbsp;The search result is limited to 100 records with the latest alerts on top of the list.</small>
                </div>
            </div>
            
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th scope="col" style={{width: "20%"}}>Alert Date</th>
                        <th scope="col" style={{width: "10%"}}>Alert Type</th>
                        <th scope="col" style={{width: "70%"}}>Message</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {this.props.list.map(item => (
                        <tr>
                            <td style={{width: "20%"}}>{item.AlertDate}</td>
                            <td style={{width: "10%"}}>
                                {item.AlertType === "Info" ?
                                    <span class="badge badge-info">{item.AlertType}</span>
                                :  item.AlertType === "Failure" ?
                                    <span class="badge badge-danger">{item.AlertType}</span>
                                :   item.AlertType === "Block" ?
                                    <span class="badge badge-warning">{item.AlertType}</span>
                                :   item.AlertType === "Success" ?
                                    <span class="badge badge-success">{item.AlertType}</span>
                                :   item.AlertType === "Manual Trigger" ?
                                    <span class="badge badge-dark">{item.AlertType}</span>
                                :   null
                                }
                            </td>
                            <td style={{width: "70%"}}>{item.Message}</td>
                        </tr>
                    ))}
                   
                </tbody>
            </table>

        </div>
            
        );
    };
};

export default AlertLogSearchResult;