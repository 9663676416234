import React, { Component } from "react";
import { MdInfoOutline } from "react-icons/md";

class FailureList extends Component {

    render() {

      let list;
      if (this.props.status === "Loading") {
        list =  <div class="alert alert-primary" role="alert">
                 Loading...
                </div>
      }
     
      if(this.props.status === "Success" && this.props.list.length > 0) {
        list = 
        <div>
          <h6 class="display-5">Failed Vendor and Manufacturing Location(s) - </h6>
          <div class="row">
            <div class="col-sm bg-light">
                <small class="text-info align-items-center">
                  <MdInfoOutline />&nbsp;The data integration pipeline will automatically process the failed vendor and location when the vendor or location is integrated next time.
                </small>
            </div>
         </div>
          <table class="table table-sm">
            <thead>
                <tr>
                    <th scope="col" style={{width: "10%"}}>Vendor Id</th>
                    <th scope="col" style={{width: "10%"}}>Location Id</th>
                    <th scope="col" style={{width: "10%"}}>Location Code</th>
                    <th scope="col" style={{width: "25%"}}>Location Name</th>
                    <th scope="col" style={{width: "20%"}}>Failure Date</th>
                    <th scope="col" style={{width: "25%"}}>Reason</th>
                </tr>
            </thead>
            <tbody>
                {this.props.list.map(item => (
                  <tr>
                    <td>{item.VendorId}</td>
                    <td>{item.LocationId}</td>
                    <td>{item.LocationCode}</td>
                    <td>{item.LocationName}</td>
                    <td>{item.FailureDate}</td>
                    <td>{item.Reason}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      }
      else if(this.props.status === "Success" && this.props.list.length === 0) 
      {
        list = <div class="alert alert-success" role="alert">
                  No vendor or manufacturing locations are currently failed in the integration pipeline.
              </div>
      }

      if(this.props.status === "Failure") {
        list = <div class="alert alert-danger" role="alert">
                  Server error occurred while getting the currently failed vendor and manufacturing locations. 
                  Please try again later!
               </div>
      }

        return (

            <div>
               {list}
            </div>
            
        );
    };
};

export default FailureList;